import React from 'react';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
  } from '@tanstack/react-query'
import axios from 'axios';
import { Icon } from '@iconify/react';

export default function CartCard(props) {
/*     axios.defaults.withCredentials = true;
    const { isLoading, error, data, isFetching } = useQuery(["repoData"], () =>
    axios
      .get("http://localhost:6100/api/fetchProds",{ withCredentials: true })
      .then((res) => res.data)
  );
    if (isLoading) return "Loading...";
    if (error) return "An error has occurred: " + error.message;
    if(data) return console.log(data);
 */

    return (
        <li class="flex flex-col space-y-3 py-4 text-left sm:flex-row sm:space-x-5 sm:space-y-0">
                <div class="shrink-0">
                  <img class="h-24 w-24 max-w-full rounded-lg object-cover" src={props.img1} alt="" />
                </div>

        <div class="relative flex flex-1 flex-col justify-between">
            <div class="sm:col-gap-1 sm:grid sm:grid-cols-2">
            <div class="pr-8 sm:pr-5">
                <p class="text-base font-semibold text-gray-900">{props.productName}</p>
                <p class="mx-0 mt-1 mb-0 text-sm text-gray-400">{props.underName}</p>
            </div>

            <div class="mt-4 flex items-end justify-between sm:mt-0 sm:items-start sm:justify-end">
                <p class="shrink-0 w-20 text-base font-semibold text-gray-900 sm:order-2 sm:ml-1 sm:text-right">{props.productPrice} €</p>

                <div class="sm:order-1">
                <div class="mx-auto flex h-8 items-stretch text-gray-600">
                    <button data-itemid={props.productID} class="flex items-center justify-center rounded-l-md bg-gray-200 px-4 transition hover:bg-black hover:text-white" onClick={props.minusFnct}>-</button>
                    <div class="flex w-full items-center justify-center bg-gray-100 px-4 text-xs uppercase transition">{props.productQuantity}</div>
                    <button data-itemid={props.productID} class="flex items-center justify-center rounded-r-md bg-gray-200 px-4 transition hover:bg-black hover:text-white" onClick={props.plusFnct}>+</button>
                </div>
                </div>
            </div>
            </div>

            <div class="absolute top-0 right-0 flex sm:bottom-0 sm:top-auto">
            <button data-itemid={props.productID} onClick={props.deleteFnct} type="button" class="h-fit flex cursor-pointer rounded p-2 text-center text-gray-500 transition-all duration-200 ease-in-out focus:shadow hover:text-gray-900" >
              <Icon className='text-3xl' icon="clarity:remove-line" />
            </button>
            </div>
      </div>
      </li>
        )
}

            



