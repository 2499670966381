import { Icon } from "@iconify/react"
import {BrowserRouter as Router, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function Footer() {

  const {t} = useTranslation();


  return (  
    <footer class="footer p-5 md:p-10 bg-neutral text-neutral-content flex flex-col md:flex-row items-center justify-around">
    <div className="">
      <img loading="lazy" className="w-14" src="images/Shots_Ladders Sticker.png" alt=""/>
     {/*  <p>Shots&Ladders sp.<br />Providing sane alcoholics since 2022™</p> */}
    </div> 
    <div>
      <span class="footer-title">{t('footer.social')}</span> 
      <div class="grid grid-flow-col gap-4">
        <a target="_blank" rel="noreferrer" href="https://www.facebook.com/simplestdrinkinggame">
          <Icon className="m-1 text-4xl text-blue-800" icon="akar-icons:facebook-fill" loading="lazy" />
        </a>
         <a target="_blank" rel="noreferrer" href="https://www.instagram.com/simplestdrinkinggame/">
            <img loading="lazy" alt="instagram logo" src="https://img.icons8.com/fluency/48/000000/instagram-new.png"/>
         </a>
         <a target="_blank" rel="noreferrer" href="https://www.tiktok.com/@simplestdrinkinggame">
          <Icon className='m-1 text-4xl' icon="logos:tiktok-icon" loading="lazy" />
        </a>
      </div>
    </div>
    <div className="">
    <span class="footer-title">{t('footer.legal')}</span> 
    {/* <a class="link link-hover">Terms of use</a> 
    <a class="link link-hover">Privacy policy</a>  */}
    <Link   to="cookie-policy"
            className="link link-hover">
              {t('footer.cookies')}
                                        </Link>
    </div> 
    
    </footer>
    )

}
