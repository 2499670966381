
import React, {useEffect} from 'react';
import loadable from '@loadable/component'
import {BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ParallaxProvider } from 'react-scroll-parallax';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useTranslation} from 'react-i18next';
import axios from 'axios';
import i18n from './i18n';
// KEY 6LerNyogAAAAAD9X1A4eSHdO2L5hhEukHWdme0EE
// SECRET 6LerNyogAAAAAIF9IZhrtubuV0VZOTgYc4h-BgqQ

import ScrollToTop from './scrollToTop';
import Cookies from './Cookies';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';




import Header from './Components/Header';
import Footer from './Components/Footer';

// Splitter imports
const Home = loadable(() => import('./Pages/Home'))
const Shop = loadable(() => import('./Pages/Shop'))
const ErrorPage = loadable(() => import('./Pages/ErrorPage'))
const Blog = loadable(() => import('./Pages/Blog'))
const Kontakt = loadable(() => import('./Pages/Kontakt'))
const CookiePolicy = loadable(() => import('./Pages/cookiePolicy'))
const Kickstarter = loadable(() => import('./Pages/Kickstarter'))
const PurchaseSuccess = loadable(() => import('./Pages/orderThanks'))

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
const stripePromise = loadStripe("pk_live_51LFXqDH0yNSM3glvruXUwtte70X4p8BIcZgDu12H0P3FRHLEUzcKH3G1c9bl3jeQKkizcrq5vnbTIRZ0FGNUMtcL00wFyMcNl3");



const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: twentyFourHoursInMs,
    },
  },
});


function App() {
  
  axios.defaults.withCredentials = true;

  const { t } = useTranslation();

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    const ele = document.getElementById('ipl-progress-indicator')
    if(ele){
      // fade out
      ele.classList.add('available')
      setTimeout(() => {
        // remove from DOM
        ele.outerHTML = ''
      }, 2000)
    }
  });
  

  return (
    <QueryClientProvider client={queryClient}>
      <Elements stripe={stripePromise}>    
      <Router >
      
        <Header />
        <ScrollToTop/>
        <Cookies/>
        <ParallaxProvider>
        <Routes>
        
            <Route path="/" element={<Navigate to ={i18n.language} />} />
            
            <Route path={t('menuLines.0.link')} element={<Home title="Shots & Ladders - The simplest drinking game!" />}  />
            <Route path={t('menuLines.1.link')} element={<Blog />}  />
            <Route path={t('menuLines.2.link')} element={<Shop />}  />
            <Route path="/thank-you" element={<PurchaseSuccess />}  />
            
            <Route path={t('menuLines.3.link')} element={<Kontakt title="Shots & Ladders - Contact us" />}  />
            <Route path="/cookie-policy" element={<CookiePolicy />}  />
            <Route path={"*"} element={<ErrorPage/>}  />
          
             <Route path="/kickstarter" element={<Kickstarter title="Shots & Ladders - Kickstarter pledge shipping cost" />}  />
          
             
        </Routes>
        <Footer />
        </ParallaxProvider>
      </Router>
      </Elements>
    </QueryClientProvider>
  );
}

export default App;
