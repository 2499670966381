import React, { useEffect } from 'react';
import { Icon } from '@iconify/react';
import {BrowserRouter as Router, Link, useNavigate, useLocation  } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import useWindowPosition from '../Functions/useWindowPosition';
import {useMutation, useQuery} from '@tanstack/react-query'
import axios from 'axios';
import CartCard from './CartCard';
import { array } from 'yup';
//import ReactDOM from 'react-dom';


/* function updateCartItems(){
    return ();
} */
function usePosts_() {
    axios.defaults.withCredentials = true;
    return useQuery(["carts"], async () => {
      const { data } = await axios.get(
        "https://api.shotsandladders.shop/api/fetchCart"
      );
      return data;
    });
  }

function Header() {
  /*   let testingvar = [];
    console.log(testingvar);
   function trest () { return Array.isArray(testingvar);};
    console.log(trest()); */
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    let scroll = useWindowPosition();
    const [cartState,setcartState] = React.useState("hidden");
    const [hideSpinner,sethideSpinner] = React.useState("hidden");

    const cartQtyMutation = useMutation(CartItem => axios.post('https://api.shotsandladders.shop/api/updateCart', { CartItem }),
    {
         onSuccess: (databack, variables, context) => {
          /*  console.log(databack); */              
          },
         onError: (error, variables, context) => {
        // I will fire first
            },
    }
  )

  const checkoutMutation = useMutation(line_items => axios.post('https://api.shotsandladders.shop/api/checkouts', { line_items }),
  {
       onSuccess: (databack, variables, context) => {
        
        console.log("This is my checkout LINK");     
          console.log(databack);
          window.location.href =databack.data.url;
        
        },
       onError: (error, variables, context) => {
      // I will fire first
          },
  }
)
    
    const [visinaMenija,setVisinaMenija] = React.useState({
        visina: "lg:h-24",
        slika: "lg:w-1/3"
        });


    useEffect(() => {
        if (scroll === 0) {
            /* console.log("naVrhu"); */
            setVisinaMenija({
                visina: "lg:h-24",
                slika: "lg:w-1/3"
                });
        } else {
           /*  console.log("Bolj dol") */
            setVisinaMenija({
                visina: "lg:h-16",
                slika: "lg:w-1/4"
                });
        } 
    },
    // array of variables that can trigger an update if they change. Pass an
    // an empty array if you just want to run it once after component mounted. 
    [scroll])
    

     /* console.log(useWindowPosition());  */
/*     useEffect(() => {
        setcartContent(window.cartStatus)
        console.log(cartContent);
    },
    window.cartStatus) */
    
     
    const [odprtostMenija,setOdprtostMenija] = React.useState({
        odprtost: "scale-y-0",
        rotacija: "-mt-8",
        rotacija2: "-mt-6",
        rotacija3: "-mt-4"
    });
    const [hide,setHide] = React.useState(true);
    const [isitHidden,setisitHidden] = React.useState("");
    

    
   
    function TranslateClick(lang) {
        
        let currLang = i18n.changeLanguage(lang);
       
        /*  const currentPathname = window.location.pathname.replace(/\/+$/, ''); */
     
        
        setTimeout(function() { 
            currLang = i18n.language;
            console.log(i18n.language);
            console.log(location.pathname);
            const lastItem = (location.pathname).substring((location.pathname).lastIndexOf('/') + 1);
            console.log(lastItem);
           if (lastItem.length > 2) {
            let sendToURL = "/"+currLang+"/"+lastItem+"";
            navigate(sendToURL);
           } else {
            let sendToURL = "/"+currLang+"";
            console.log(sendToURL);
            navigate(sendToURL);
           }
         }, 100);
        document.activeElement.blur();
       setHide("hidden");
       setTimeout(function() { setHide("") }, 100);
    }
    const { status, data, error, isFetching, refetch} = usePosts_();
    


    function odpriMeni() {
        odprtostMenija.odprtost === "" ? setOdprtostMenija({
            odprtost: "scale-y-0",
            rotacija: "-mt-8",
            rotacija2: "-mt-6",
            rotacija3: "-mt-4"
        }) : setOdprtostMenija({
            odprtost: "",
            rotacija: "-mt-6 rotate-45",
            rotacija2: "-mt-6 -rotate-45",
            rotacija3: "-mt-6 -rotate-45"
        })

    }
   function reduceQty(e) {
    if (data || data.cart!==[]) { // check if there is any data
        for (let i = 0; i < data.length; i++) {
            if (data[i].id == e.target.dataset.itemid){ 
                if(data[i].productQuantity>1){
                    data[i].productQuantity--;
                    cartQtyMutation.mutate(data[i]);
                }
            
            } 
            
     }
    }
   }

   function increaseQty(e) {
    //console.log("am I here?");
    if (data || data.cart!==[]) { // check if there is any data
       // console.log("Yes There is data");
        for (let i = 0; i < data.length; i++) {
            if (data[i].id == e.target.dataset.itemid){ 
                //console.log(e.target.dataset.itemid);
                if(data[i].productQuantity<99){
                    data[i].productQuantity++;
                    cartQtyMutation.mutate(data[i]);
                }
            
            } 
        }
    }   
}

function proceedToCheckout() {
    let line_items =[];
    if (data && Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
        line_items[i] = {
            price: data[i].default_price,
            quantity: data[i].productQuantity  
            }
        }
        checkoutMutation.mutate(line_items);
        sethideSpinner("")
    } else {
        alert("You cart is empty and therefore you cannot proceed to the checkout.");
    }
    
}
let informacijecarta=[]
function checkifArray () {
    console.log(data);
    if (data.length>0) { 
       return false;
} else {
    setisitHidden("hidden");
    return true;

}
}
 async function deleteItem(e) {
    console.log("am I here?");
    if (data && Array.isArray(data)) { // check if there is any data
        console.log("Yes There is data");
        for (let i = 0; i < data.length; i++) {
           if(e.target.dataset.itemid === data[i].id ) 
                console.log(e);
                if(data[i].productQuantity>0){
                    data[i].productQuantity=0;
                    console.log(data[i]);
                    await cartQtyMutation.mutate(data[i]);
                    data.splice(i, 1);
                    if ( !data.length>=1) {
                        refetch();
                    }
                    break;
                }
            
             
        }
    }
}

    return (<>
    <header className={"fixed top-0 z-50 w-full bg-temnoZelena border-b border-gray-900 shadow-xl h-20 md:h-28 transition-all duration-200 "+visinaMenija.visina}>
        <div className="flex flex-row items-center justify-between w-full h-full text-white lg:flex-row">
            {/* Dropdown za izbiro jezika jezik */}
            <div class="dropdown dropdown-hover hidden lg:block flex-1 text-semibold uppercase tracking-wider">
                <label tabindex="0" class="btn m-1 border-0 bg-temnoZelena hover:bg-turkizna w-max">
                            <Icon icon="iconoir:language" className='mx-1' />
                            {t('chooseLang.gumb')}
                </label>
                <ul tabindex="0" class={"bg-temnoZelena hover:bg-turkizna dropdown-content menu p-2 shadow rounded-box w-52 "+hide}>
                        <li className=''><a onClick={()=>TranslateClick('en')}>
                            <Icon icon="emojione:flag-for-united-kingdom" />
                            {t('chooseLang.lang1')}</a></li>
                        <li className='myclass_hover'><a onClick={()=>TranslateClick('sl')}>
                            <Icon icon="emojione:flag-for-slovenia" />
                            {t('chooseLang.lang2')}</a></li>
                        <li><a onClick={() => { TranslateClick('sp');}}>
                                        <Icon icon="emojione:flag-for-spain" />
                                            {t('chooseLang.lang4')}</a></li>
                        <li><a onClick={()=>TranslateClick('hr')}>
                            <Icon icon="emojione:flag-for-croatia" />
                            {t('chooseLang.lang3')}</a></li>
                </ul>
            </div>
            {/* SREDINA SLIKA IN GUMBI */}                                                                                           
            <Link className={"m-2 cursor-pointer md:h-auto md:p-4 transition-all w-64 xl:w-4/12 duration-150 "+visinaMenija.slika} to="/"><img  src="/images/Shots_&_Ladders_Header.png" alt="shots and ladders logo"/></Link>
                                           
                                       
                
            <div className='hidden lg:flex flex-1 justify-center' >
              {/*  <Link
                                            className="m-2 text-xl tracking-wide transition-all hover:text-gold font-naslovi hidden lg:flex"
                                            to="/test">
                                            KONTAKT
                                        </Link>  */}
                {t('menuLines',  { returnObjects: true }).map( (element,index) => {
                        if (index === 1) return (<></>)
                                return  (<Link
                                            className="m-2 text-xl tracking-wide transition-all font-naslovi hover:text-turkizna btn"
                                            key = {index}
                                            to={element.link}>
                                            {element.text}
                                        </Link>)
                                }) } 
                                
                                
            </div>
            
                <div className='w-24 text-white transition-all duration-300 cursor-pointer lg:hidden ' onClick={odpriMeni} >
                    <Icon className={"phoneMenuIcon "+ odprtostMenija.rotacija}  icon="ant-design:line-outlined"  />
                    <Icon className={"phoneMenuIcon "+ odprtostMenija.rotacija2}  icon="ant-design:line-outlined"  />
                    <Icon className={"phoneMenuIcon "+ odprtostMenija.rotacija3}  icon="ant-design:line-outlined" />
                </div>
                <div className="indicator">
                <div className="drawer-content">
                     <span className="indicator-item indicator-start badge badge-secondary mt-2 hidden">{data? data.length: "0"}</span>
                    <label htmlFor="my-drawer-4" className="btn btn-neutral" onClick={()=>{refetch(); if(cartState=="hidden") setcartState(""); else setcartState("hidden")}}><Icon className='text-4xl' icon="carbon:shopping-bag" /></label>
                </div>
                </div>             
                                {/*  Shopping cart drawer */}
                

          
        </div>
    </header>
    {/*  DEJANSKI MENI za TELEFON */}
    <div className={"w-full fixed top-0 mt-20 h-[calc(100vh)] bg-neutral uppercase tracking-wider transition-all origin-top  duration-300 z-50 " + odprtostMenija.odprtost }>
        <div className="overflow-y-auto h-[calc(100%_-_3rem)]">
        
                              {t('menuLines',  { returnObjects: true }).map( (element,index) => {
                                
                        if (index === 1) return (<></>)
                                return  (<Link
                                            className="flex m-2 text-xl tracking-wide transition-all font-naslovi hover:text-turkizna btn"
                                            key = {index}
                                            to={element.link}
                                            onClick={odpriMeni}>
                                            {element.text}
                                        </Link>)
                                }) } 
                               
                            <div class="dropdown flex-1 text-temnoZelena flex justify-center font-semibold">
                                <label tabindex="0" class="btn p-0 px-0 border-0 bg-temnoZelena hover:bg-turkizna w-max">
                                            {t('chooseLang.gumb')}
                                            <Icon icon="iconoir:language" className='mx-1 text-lg' />
                                </label>
                                <ul tabindex="0" class={"bg-turkizna hover:bg-turkizna dropdown-content menu p-2 shadow rounded-box w-52 "+hide}>
                                        <li className=''><a onClick={() => { TranslateClick('en'); odpriMeni();}}>
                                            <Icon icon="emojione:flag-for-united-kingdom" />
                                            {t('chooseLang.lang1')}</a></li>
                                        <li className='myclass_hover'><a onClick={() => { TranslateClick('sl'); odpriMeni();}}>
                                            <Icon icon="emojione:flag-for-slovenia" />
                                            {t('chooseLang.lang2')}</a></li>
                                        <li><a onClick={() => { TranslateClick('sp'); odpriMeni();}}>
                                        <Icon icon="emojione:flag-for-spain" />
                                            {t('chooseLang.lang4')}</a></li>
                                        <li><a onClick={() => { TranslateClick('hr'); odpriMeni();}}>
                                        <Icon icon="emojione:flag-for-croatia" />
                                            {t('chooseLang.lang3')}</a></li>
                                </ul>
                            </div>          
               
                <div className="flex flex-col w-full h-auto pt-4 ">
                    <h3 className="flex justify-center text-white font-semibold">{t('phoneMenu.social')}</h3>
                    <div className="flex flex-row justify-center align-middle gap-4 mt-2">
                    <a target="_blank" rel="noreferrer" href="https://www.facebook.com/simplestdrinkinggame">
                        <Icon loading="lazy" className="m-1 text-4xl text-blue-800" href="https://www.facebook.com/simplestdrinkinggame" icon="akar-icons:facebook-fill" />
                    </a>
                    
                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/simplestdrinkinggame/">
                        <img loading="lazy" alt="instagram icon" src="https://img.icons8.com/fluency/48/000000/instagram-new.png"/>
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://www.tiktok.com/@simplestdrinkinggame">
                    <Icon loading="lazy" className='m-1 text-4xl' icon="logos:tiktok-icon" />
                    </a>
                    
                    </div>
                    <div className="flex flex-row items-center justify-center mt-2">
                        {/* <Icon className="text-base text-white" icon="carbon:phone-filled" /><a className="p-1 text-lg text-white" href="tel:031-337-356" > | 031 337 356</a> */}

                    </div>
                    <div className="flex flex-row items-center justify-center align-middle m-1">
                        <Icon loading="lazy" className="text-base text-white" icon="fluent:mail-24-regular" /><a className="p-1 text-lg text-white lowercase" href="mailto:vid@shotsandladders.si" > | vid@shotsandladders.si</a>

                    </div>

                </div>
        </div>
                {/* <footer className="fixed bottom-0 flex flex-col items-center content-center w-full h-12 p-2 text-xs text-white normal-case bg-error">
                    <p>Streli & Lojtra | Vse pravice pridržane © {leto}</p>
                    <a className="p-1 text-blue-600 underline hover:text-blue-800" href="/politikaZasebnosti" >Politika zasebnosti</a>



                </footer> */}

    </div>
    <div className={"drawer drawer-end fixed z-20 transition-all duration-100 "+cartState}>
                <input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
                
                <div className="drawer-side pt-16">
                    <label htmlFor="my-drawer-4" className="drawer-overlay" onClick={()=>{ if(cartState=="hidden") setcartState(""); else setcartState("hidden")}}></label>
                    <ul className="menu p-4 pt-10 overflow-y-auto w-11/12 lg:w-5/12 bg-base-100 text-base-content">
                  {/*   <!-- Sidebar content here --> */}
                    <h2 className='text-2xl font-naslovi'> Your Cart</h2>
                    <div>
                       { Array.isArray(data) ? data?.map((element,index)=> {
                        checkifArray();
                        return  (<CartCard
                                    className ="p-2"
                                    key={index}
                                    productName={element.name}
                                    productID={element.id}
                                    productDescription={element.description}
                                    productPrice={element.actualPrice/100}
                                    img1={element.images[0]}
                                    productQuantity={element.productQuantity}
                                    minusFnct={reduceQty}
                                    plusFnct={increaseQty}
                                    deleteFnct={deleteItem}/>)      
                       }) : <li className={'text-xl '+isitHidden}>Nothing to see here 😟<br></br>You must first add a product from the shop. </li> }

                        
                         {/* {<li className={'text-xl '+isitHidden}>Nothing to see here 😟<br></br>You must first add a product from the shop. </li> }  */}
                 
                        </div>
                    <div>
                        <button type="button" 
                        onClick={proceedToCheckout}
                        className="group inline-flex w-full items-center justify-center rounded-md bg-gray-900 px-6 py-4 text-lg font-semibold text-white transition-all duration-200 ease-in-out focus:shadow hover:bg-gray-800">
                        <div className={'absolute flex justify-center items-center left-0 top-0 w-full h-full bg-slate-900 opacity-80 z-20 rounded-lg '+ hideSpinner}>
                            <div class="lds-hourglass text-accent"> </div>   
                        </div>
                        Checkout
                        <svg xmlns="http://www.w3.org/2000/svg" className="group-hover:ml-8 ml-4 h-6 w-6 transition-all" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                        </svg>
                        </button>
                        </div>
                    </ul>
                      
                </div>
          
    </div>

</>
);
                    }
export default Header;

 {/* 
                       data?.map( (element,index) => {
                            return  (<CartCard
                                    className ="p-2"
                                    key={index}
                                    productName={element.name}
                                    productID={element.id}
                                    productDescription={element.description}
                                    productPrice={element.actualPrice/100}
                                    img1={element.images[0]}
                                    productQuantity={element.productQuantity}
                                    minusFnct={reduceQty}
                                    plusFnct={increaseQty}
                                    deleteFnct={deleteItem}
                                />)
                      }) */} 
                      {/* <li className='text-xl'>Nothing to see here 😟<br></br>You must first add a product from the shop. </li>  */}